import React, { Suspense } from 'react';
import { AuthContextProvider } from './hooks/useToken';
import ToastProvider from './providers/ToastProvider';
import { TranslateProvider, getStoredLanguage } from './translations';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes } from './Routes';
import { ViewportProvider } from './providers/ViewportProvider';
import { ConfirmProvider } from './providers/ConfirmProvider';
import { ThemeSelector } from './styles/themes/ThemeSelector';
import { getAppName } from './utils/getAppName';
import { TooltipContainer } from './components/tooltip';
import { JobQueueProvider } from './features/jobs-queue';

import 'react-tooltip/dist/react-tooltip.css';
import { QueryErrorProvider } from './providers/QueryErrorProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const appName = getAppName();

const storedLanguage = getStoredLanguage();

const App: React.FC = () => {
  return (
    <Suspense>
      <TranslateProvider defaultLocale={storedLanguage}>
        <ThemeSelector themeName={appName}>
          <ViewportProvider>
            <QueryClientProvider client={queryClient}>
              <AuthContextProvider>
                <ConfirmProvider>
                  <ToastProvider>
                    <QueryErrorProvider>
                      <JobQueueProvider>
                        <Routes />
                        <TooltipContainer />
                      </JobQueueProvider>
                    </QueryErrorProvider>
                  </ToastProvider>
                </ConfirmProvider>
              </AuthContextProvider>
            </QueryClientProvider>
          </ViewportProvider>
        </ThemeSelector>
      </TranslateProvider>
    </Suspense>
  );
};

export default App;
